<template>
  <div style="margin-bottom: 100px">
    <b-container>
      <h2 class="font-weight-bolder text-colorBlue text-center pt-1 pb-1">
        Car Rental
      </h2>

      <b-row class="mt-3">
        <b-col
          md="6"
          lg="4"
          v-for="(car, index) in cars"
          :key="index"
          class="d-flex flex-column justify-content-between"
          :class="{ 'bg-colorGrey rounded': isHovered === index }"
          @mouseover="isHovered = index"
          @mouseleave="isHovered = null"
        >
          <div class="w-100 d-flex">
            <div class="w-75 h-100">
              <img :src="car.image" class="img-fluid rounded p-0" alt="image" />
            </div>
            <div class="w-25">
              <p
                class="font-weight-bolder text-colorText m-0 p-0 text-center"
                style="font-size: 0.7rem"
              >
                starting from
              </p>
              <h3 class="font-weight-bolder text-colorGreen text-center">
                {{ car.amount }}$
                <span style="font-size: 16px"> /day</span>
              </h3>
            </div>
          </div>
          <div>
            <h5 class="font-weight-bolder text-colorText text-center">
              {{ car.name }}
            </h5>
          </div>
          <!-- <div class="text-center">
            <b-button
              block
              rounded
              :variant="isInCart(car) ? 'colorBlue' : 'colorGreen'"
              size="md"
              class="font-weight-bolder px-5 mb-sm-1"
              @click="handleCartAction(car)"
            >
              {{ isInCart(car) ? 'View Cart' : 'Add to Cart' }}
            </b-button>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      isHovered: null,
      cars: [],
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
  async mounted() {
    try {
      // this.initializeCart();

      const res = await this.getRentalCarsPublic({});
      if (res.status === 200) {
        this.cars = res.data;
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getRentalCarsPublic: "appData/getRentalCarsPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
      // initializeCart: "appData/initializeCart",
    }),
    addCart(item) {
      this.addToCart({
        id: `${item.id}_${this.cartItemType.CAR}`,
        itemId: item.id,
        name: item.name,
        price: item.amount,
        type: this.cartItemType.CAR,
        quantity: 1,
        // viewCart: this.viewCart,
        numberOfDays: 1,
      });
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Item added to cart successfully",
            variant: "success",
            icon: "BellIcon",
          },
        },
        {
          position: "top-right",
        }
      );
    },
    handleCartAction(item) {
      if (this.isInCart(item)) {
        this.$router.push({ name: "Cart" }); // Navigate to cart page
      } else {
        this.addCart(item);
      }
    },
    isInCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.CAR}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style scoped>
.bg-colorGrey {
  background-color: #f0f0f0; /* Adjust to your preferred grey color */
}
</style>
