<template>
  <div class="my-bg d-flex align-items-center">
    <b-container>
      <div>
        <h1 class="font-weight-bolder text-colorBlue">
          Medical Tourism Car Rental
        </h1>
      </div>
      <div class="pt-2">
        <h4 class="font-weight-normal text-colorGreen">
          Most Extensive & Affordable Medical Procedures
        </h4>
      </div>
      <div class="pt-2 d-flex justify-content-start">
        <b-button
          pill
          variant="colorGreen"
          size="md"
          class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
          @click="contactPage"
        >
          <img
            src="@/assets/images/icons/button2.svg"
            class="logo mr-50"
            alt="image"
          />
          Contact Us
        </b-button>
        <b-button
          pill
          variant="colorGreen"
          size="md"
          class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
          @click="getQuote"
        >
          <img
            src="@/assets/images/icons/button1.svg"
            class="mr-50"
            alt="image"
          />Get a Quote
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredCol1: false,
      hoveredCol2: false,
      hoveredCol3: false,
      hoveredCol4: false,
    };
  },
  methods: {
    contactPage() {
      this.$router.push({ name: "ContactUs" });
    },
    getQuote() {
      this.$router.push({ path: "/", hash: "#getQuote" });
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/rental/header.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
